import { Helmet } from "react-helmet";

export default function Root() {
  return (
    <>
      <Helmet>
        <title>OpenChair</title>
      </Helmet>
      <div
        className="flex flex-col flex-1 items-center justify-center"
        style={{ maxWidth: "1312px" }}
      >
        <img
          alt="openchair logo"
          src={require("assets/wordmark.png")}
          className="sm:w-96 w-52 mb-36"
        />
      </div>
    </>
  );
}
