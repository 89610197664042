import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Footer from "components/Footer";

import Root from "routes/Root";

export default function Router() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={null}>
        <div className="h-screen flex items-center flex-col w-screen">
          <Routes>
            <Route path="/" element={<Root />} />
          </Routes>
          <Footer />
        </div>
      </React.Suspense>
    </BrowserRouter>
  );
}
