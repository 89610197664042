import linkedinIcon from "assets/linkedin.svg";
import instagramIcon from "assets/instagram.svg";

export default function Footer() {
  return (
    <div className="w-screen fixed inset-x-0 bottom-0">
      <div className="flex flex-col w-full h-16 justify-center items-center">
        <div
          className="flex flex-col justify-center sm:mx-16 mx-4 w-full"
          style={{ maxWidth: "1312px" }}
        >
          <div className="items-center bg-mono-white w-full justify-between md:flex flex flex-col z-6 sm:px-16 px-4 sm:mb-0 mb-4">
            <div className="flex items-center space-x-2">
              <a
                href="https://www.linkedin.com/company/open-chair-technologies"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="linked in" src={linkedinIcon} />
              </a>
              <a
                href="https://www.instagram.com/octbots"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="instagram" src={instagramIcon} className="mt-0.5" />
              </a>
            </div>
            <p className="text-mono-450 caption">
              © 2022 Open Chair Technologies, Inc.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
